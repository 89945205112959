import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  VStack,
  Code,
  Grid,
  Tabs,
  TabList,
  Tab,
  Image,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import "./style.css";

export const Home: React.FC = () => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<any>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedLink = userInfo?.menus[selectedTabIndex]?.link;
  const selectedMenuId = userInfo?.menus[selectedTabIndex]?.menu_id;

  useEffect(() => {
    // 获取token
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(API_ENDPOINT + "/users/profile", {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);

            response.data.menus.unshift({
              menu_id: -99,
              user_id: 0,
              menu_name: "我的文件库",
              link: "https://chato.cn/b/gwk6d70111jrve1o",
              sort: 0,
            });

            response.data.menus.push({
              menu_id: -100,
              user_id: 0,
              menu_name: "照片管理",
              link: "https://chato.cn/b/gwk6d70111jrve1o",
              sort: 0,
            });

            setUserInfo(response.data);
          }
        })
        .catch((error) => {
          localStorage.removeItem("token");
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <body>
      <div className="sidenav">
        <img src="/images/logo.svg" className="logo"></img>
        <Tabs
          orientation="vertical"
          variant="soft-rounded"
          colorScheme="blackAlpha"
          index={selectedTabIndex}
          onChange={(index) => setSelectedTabIndex(index)}
        >
          <TabList className="nav-link-container" color="white" w="100%">
            {userInfo?.menus.map((item: any) => (
              <Tab color="white" key={item.menu_id}>
                <a className="nav-link" href="#">
                  {item.menu_name}
                </a>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <div style={{ display: "flex" }}>
          <button className="custom-btn btn-03">探索更多</button>
        </div>
      </div>

      <div className="my-container">
        <div className="main">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "10px",
            }}
          >
            <div className="left-column-container">
              <img
                style={{ width: "100%", margin: "5px" }}
                src="/images/search-bar.svg"
              ></img>
              {selectedMenuId === -99 ? (
                <Image
                  src="/images/files.svg"
                  alt="files"
                  style={{ width: "98%", margin: "5px" }}
                />
              ) : selectedMenuId === -100 ? (
                <Image
                  src="/images/image_manage.png"
                  alt="image manage"
                  style={{ width: "98%", margin: "5px" }}
                />
              ) : (
                <div id="iframe-wrapper" className="corner-wrapper">
                  <iframe
                    id="inlineFrameExample"
                    className="frame"
                    title="Inline Frame Example"
                    width="100%"
                    src={selectedLink}
                  ></iframe>
                </div>
              )}
            </div>
            <div style={{ width: "30%", padding: "10px" }}>
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                mr="60px"
                mb="20px"
              >
                <HStack spacing={4} mt="30px" w="calc(100% - 50px)">
                  <Menu>
                    <>
                      <MenuButton
                        as={Button}
                        textAlign="left"
                        bg="white"
                        color="gray.500"
                        p={"12px 18px"}
                        w="100%"
                        h="60px"
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box>
                            <Text>Hello,</Text>
                            <Text>{userInfo?.user?.username}</Text>
                          </Box>
                          <TriangleDownIcon mr="30px" color="gray.500" />
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={handleLogout}
                          fontSize="16px"
                          color="gray.600"
                        >
                          退出登录
                        </MenuItem>
                      </MenuList>
                    </>
                  </Menu>
                  <Avatar size="lg" m="-50px" />
                </HStack>
              </Flex>
              {selectedMenuId === -99 ? (
                <Image src="/images/data.svg" alt="files" />
              ) : selectedMenuId === -100 ? (
                <Image src="/images/data.svg" alt="image manage" />
              ) : (
                <>
                  <img
                    style={{ width: "100%", margin: "5px" }}
                    src="/images/add_task.svg"
                  />

                  <img
                    style={{ width: "100%", margin: "5px" }}
                    src="/images/side_ai_creator.svg"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="contact-container">
          <div style={{ width: "90%", display: "flex", flexDirection: "row" }}>
            <div style={{ margin: "15px" }}>
              <h3 style={{ color: "white" }}>工作时间</h3>
              <p style={{ color: "white" }}>
                <span style={{ fontWeight: "700", color: "rgb(0, 181, 235)" }}>
                  周一-周五
                </span>{" "}
                8:30-18:30
              </p>
            </div>
            <div style={{ margin: "15px" }}>
              <h3>联系我们</h3>
              微信公号：kelingo
              <br />
              联系电话：020-84133051
            </div>
            <div style={{ margin: "15px" }}>
              <h3>服务条款</h3>
              <a style={{ color: "gray" }} href="#">
                条款及条件
              </a>
              <br />
              <a style={{ color: "gray" }} href="#">
                隐私政策
              </a>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};
