import { Home, Login } from "views";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  </Router>
);
